.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.logipage {
  padding-top: 50px;
}

.mainlogin {
  width: 86%;
}
.demoForm p {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
.toploginbox {
  height: 244px;
  background-color: #2688db;
  padding-top: 60px;
}

form.demoForm button {
  width: 60%;
  margin: auto !important;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -22px;
  height: 49px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*================== Error Page =======================*/
.alert-css{
  text-align: center;
  color: darkblue;
}

.error-image{
  margin-left: 180px;
}

/* ================= login page start====================*/

.login-section {
  background-color: #f7f7f7;
  padding: 15px;
  width: 21%;
  height: 300px;
  margin: auto;
  border: solid 1px #cecece;
  border-radius: 4px;
  position: relative;
  top: 200px;
}

.login-section button {
  background-color: #09a54f;
  width: 89%;
  position: absolute;
  bottom: 15px;
  border: 0;
  height: 38px;
  color: #fff;
  font-size: 19px;
  border-radius: 4px;
  cursor: pointer;
}

.user-id {
  margin-top: 50px;
  margin-bottom: 20px;
}

.login-password span {
  margin-top: 4px;
}

.login-section ul {
  padding-left: 0;
}

.login-section ul li {
  list-style: none;
  margin-bottom: 30px;
}
.login-section ul li span {
  float: right;
  width: 76%;
}
.login-password span {
  width: auto !important;
}



/* ================= login page end======================*/



/* ================= FileUpload page start======================*/

.upload-section h1{
text-align: center;
}

.formbutton {
  margin-top: 20px;
}

.deleteicon {
  font-size: 17px !important;
  float: right;
  margin-right: 5px;
  margin-top: -2px;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 18px;
}

.button-placement{
  margin-top: 180px;
  position:relative;

}

.verified-icon{
  font-size: 20px;
}

.vertical-bar{
  background-color: grey;
  width: 50px;
}

.caicon{
  float: left;
  height: 45px;
  margin: 15px 5px 8px 8px;
}

.chat-reload{
    background-color: dodgerblue;
    padding: 5px;
    color: white;
    font-size: 15px;
    border-radius: 10px;
    margin-right: 5px;
    cursor: pointer;
    z-index: 1;
    position: fixed;
    margin-left: 15rem;
    top: 10px;
}


.name-display{
    padding-top: 10px;
    font-size: large;
    margin-left: -1110px;
    font-family: sans-serif;
    font-weight: 900;
}
.login-password{
  float:left;
}



/* ================= FileUpload page end======================*/

/* File Upload Header */

.header{
  background-color: #005ead;
  width: 100%;
  height: 70px;
  color:white;
}

.data-display{
    padding-top: 10px;
    font-size: large;
    margin-left: 270px;
    font-family: sans-serif;
    margin-bottom:-20px !important;
    bottom: 40px

}



ul.tophead {
  padding-left: 0;
  padding-top:8px;
}

ul.tophead li {
  display: inline-block;
  margin-right: 178px;
  font-size: 17px;
  font-weight: 600;
}

ul.tophead li:nth-child(1) {
  margin: 4px;
  margin-right: 91px;
}

ul.tophead li:nth-child(4) {
  display: none;
}


ul.tophead-new li {
  display: inline-block;
  margin-top: 0px;
}

ul.tophead-new {
  padding-left: 0;
}



ul.tophead-new {padding-top: 13px; margin-left: 64px;}

ul.tophead-new li:nth-child(2) {
  margin-left: 88px;
}

ul.tophead-new li:nth-child(3) {
  margin-left: 163px;
}

ul.tophead-new li:nth-child(4) {
  position: absolute;
  right: 140px;
  top: 15px;
  font-weight: bold;
}

ul.tophead-new li span:nth-child(1) {
  font-weight: bold;
}

ul.tophead-new li:nth-child(4):active{
 
}